<template>
  <div class="page" :class="{ mobile: $device.isMobileOrTablet }">
    <div class="templates-header" v-if="$route.path == '/' && waitedForRoute">
      <!-- <transition name="headers-fade"> -->
      <div class="row top-row" v-show="showPageTitle">
        <div class="text">Welcome to Madington Studio</div>
      </div>
      <!-- </transition> -->
      <!-- <transition name="headers-fade"> -->
      <div class="row bottom-row" v-show="showSelectTemplate">
        <div class="text">To get started, select a template below</div>
      </div>
      <!-- </transition> -->
    </div>

    <div class="templates-wrapper" v-if="$route.path == '/' && waitedForRoute">
      <div class="templates-group">
        <div class="template-group-header">Fluid templates</div>
        <div class="templates-rail">
          <!-- <transition-group name="templates-fade"> -->
          <NuxtLink
            v-for="(template, key, index) in groupedTemplates['portrait-landscape']"
            :to="{ name: 'template-step', params: { template: key, step: 0 } }"
            v-show="index <= shownTemplates"
            :key="key"
            tag="div"
            :data-index="index"
            class="template"
          >
            <div class="template-content">
              <div class="template-image">
                <img :src="`templates/${template.key}/${template.version}/icon.png`" />
              </div>
              <div class="template-color-layer"></div>
              <div class="template-info">
                <div class="template-info-text">
                  {{ template.meta.quicklookDescription }}
                </div>
                <div class="streamedby" v-if="template.key.includes('video')"></div>
              </div>
              <div class="template-price">
                CPM <span>{{ template.meta.CPM }}</span> NOK/SEK
              </div>
              <div class="template-new" v-if="template.meta.new"></div>
            </div>
            <div class="template-name">
              {{ template.title }}
            </div>
          </NuxtLink>
          <!-- </transition-group> -->
          <!-- <transition name="templates-fade"> -->
          <!-- <div class="template disabled" key="coming-soon" v-show="shownTemplates == Object.keys(templates).length"> -->
          <!-- <div class="template disabled" key="coming-soon">
                <div class="template-content">
                  <div class="centered-text">
                    <span>More coming soon...</span>
                  </div>
                </div>
              </div> -->
          <!-- </transition> -->
        </div>
      </div>
      <div class="templates-group" v-if="$route.path == '/' && waitedForRoute">
        <div class="template-group-header" v-show="showSelectTemplate">
          Fixed-size display templates
        </div>
        <!-- <transition-group name="templates-fade"> -->
        <NuxtLink
          v-for="(template, key, index) in groupedTemplates['netboard']"
          :to="{ name: 'template-step', params: { template: key, step: 0 } }"
          v-show="index <= shownTemplates"
          :key="key"
          tag="div"
          :data-index="index"
          class="template"
        >
          <div class="template-content">
            <div class="template-image">
              <img :src="`templates/${template.key}/${template.version}/icon.png`" />
            </div>
            <div class="template-color-layer"></div>
            <div class="template-info">
              <div class="template-info-text">
                {{ template.meta.quicklookDescription }}
              </div>
              <div class="streamedby" v-if="template.key.includes('video')"></div>
            </div>
            <div class="template-price">
              CPM <span>{{ template.meta.CPM }}</span> NOK/SEK
            </div>
            <div class="template-new" v-if="template.meta.new"></div>
          </div>
          <div class="template-name">
            {{ template.title }}
          </div>
        </NuxtLink>
        <!-- </transition-group> -->
        <!-- <transition name="templates-fade"> -->
        <!-- <div class="template disabled" key="coming-soon" v-show="shownTemplates == Object.keys(templates).length"> -->
        <!-- <div class="template disabled" key="coming-soon">
            <div class="template-content">
              <div class="centered-text">
                <span>More coming soon...</span>
              </div>
            </div>
          </div> -->
        <!-- </transition> -->
      </div>
    </div>
    <discount-info :show="showDiscountInfo" @toggle="toggleDiscountInfo" />
    <station-shutdown :show="showStudioShutdown" @toggle="toggleStudioShutdown" />
    <collapsable-info
      :show="showCollapsableInfo"
      @close="toggleCollapsableInfo"
      @open="toggleCollapsableInfo"
      v-if="$route.path == '/' && waitedForRoute"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import CollapsableInfo from "../components/CollapsableInfo";
import Discountinfo from "../components/Discountinfo";
import StationShutdown from "../components/StationShutdown";
import * as _ from "lodash";

export default {
  name: "index",
  layout: "default",
  components: {
    "collapsable-info": CollapsableInfo,
    "discount-info": Discountinfo,
    "station-shutdown": StationShutdown,
  },
  computed: {
    templates() {
      return this.$store.state.templates.templates;
    },
    groupedTemplates() {
      if (this.templates) {
        let grouped = {};
        _.each(this.templates, (template) => {
          if (typeof grouped[template.type] == "undefined") {
            grouped[template.type] = {};
          }
          grouped[template.type][template.key] = template;
        });
        return grouped;
      } else {
        return {};
      }
    },
    showCollapsableInfo() {
      return this.$store.state.showCollapsableInfo;
    },
  },
  head: function () {
    return {
      title: "Madington Studio",
      meta: [
        {
          name: `description`,
          content: `Madington Studio allows for super fast time-to-market advertising. With springloaded templates you are ready to go in just a matter of seconds.`,
        },
      ],
    };
  },
  data: function () {
    return {
      // shownTemplates: -6,
      shownTemplates: 100,
      // showPageTitle: false,
      showPageTitle: true,
      // showSelectTemplate: false
      showSelectTemplate: true,
      waitedForRoute: false,
      showDiscount: false,
      showDiscountInfo: false,
      showStudioShutdown: false,
    };
  },
  mounted() {
    this.showPageTitle = true;
    if (process.browser && this.$device.isMobileOrTablet) {
      this.$router.replace("/mobile");
    }

    this.$set(this, "showStudioShutdown", true);
    // let revealer = setInterval(() => {
    //   if(this.shownTemplates == Object.keys(this.templates).length) {
    //     clearInterval(revealer);
    //   }  else {
    //     if(this.shownTemplates == -4) this.showSelectTemplate = true
    //     this.$set(this, 'shownTemplates', this.shownTemplates + 1)
    //   }
    //
    // }, 100)
    if (process.browser) {
      this.$nextTick(() => {
        this.waitedForRoute = true;
      });
      this.$gtm.push(function () {
        this.reset();
      });
      if (!localStorage.getItem("HideCollapsableInfo")) {
        this.$store.commit("toggleCollapsableInfo");
      }
    }
  },
  beforeDestroy() {},
  methods: {
    toggleCollapsableInfo() {
      this.$set(this, "showDiscountInfo", false);
      this.$store.commit("toggleCollapsableInfo");
      localStorage.setItem(
        "HideCollapsableInfo",
        !localStorage.getItem("HideCollapsableInfo")
      );
    },
    toggleDiscountInfo() {
      this.$set(this, "showDiscountInfo", !this.showDiscountInfo);
      localStorage.setItem("HideCollapsableInfo", false);
    },
    toggleStudioShutdown() {
      this.$set(this, "showStudioShutdown", !this.showStudioShutdown);
    },
  },
};
</script>
<style lang="scss" scoped>
.headers-fade-enter-active,
.headers-fade-leave-active {
  transition: all 1.2s cubic-bezier(0.25, 1, 0.25, 1);
}
.headers-fade-enter,
.headers-fade-leave-to {
  opacity: 0;
  transform: translateY(5%);
}
.templates-fade-enter-active,
.templates-fade-leave-active {
  transition: all 1.2s cubic-bezier(0.25, 1, 0.25, 1);
}
.templates-fade-enter,
.templates-fade-leave-to {
  opacity: 0;
  transform: translateY(5%) scale(0.95);
  filter: brightness(150%);
}
.page {
  padding: 0 0 0 240px;
  &.mobile {
    &:after {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #102433;
      z-index: 10000;
      content: "";
      height: 100vh;
      width: 100vw;
    }
  }
}
.templates-header {
  padding: 0 0 2em 0;
  .row {
    &.top-row {
      padding: 2em 2.5em 0.3em;
      position: relative;
      z-index: 2;
      color: #2196f3;

      .text {
        font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
        font-weight: 500;
        font-size: 1.6em;
      }
    }
    &.bottom-row {
      padding: 0.1em 2.5em 1em;
      position: relative;
      .text {
        font-size: 0.8em;
        position: relative;
        display: inline-block;
        color: #102433;
      }
    }
  }
}
.templates-wrapper {
  padding: 0 0 1.5em 0;
  margin: 0 2em;
}
.templates-group {
  padding-bottom: 1em;
}
.template-group-header {
  // font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
  // font-weight: 500;
  font-size: 0.7em;
  text-transform: uppercase;
  color: #8e9598;
  margin-left: 1em;
  position: relative;
  z-index: 2;
  margin-bottom: 0.7em;
}
.template {
  padding: 0.5rem;
  display: inline-block;
  width: 20%;
  max-width: 300px;
  vertical-align: top;
  font-size: 1rem;
  @media (max-width: 1400px) {
    width: 25%;
  }
  @media (max-width: 1000px) {
    width: 33.33%;
  }
  @media (max-width: 800px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  &-content {
    position: relative;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 -2.8px 2.2px rgba(255, 255, 255, 0.12),
      0 -6.7px 5.3px rgba(255, 255, 255, 0.13), 0 -12.5px 10px rgba(255, 255, 255, 0.14),
      0 -22.3px 17.9px rgba(255, 255, 255, 0.14),
      0 -41.8px 33.4px rgba(255, 255, 255, 0.15), 0 -100px 80px rgba(255, 255, 255, 0.17),
      0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035), 0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
  }
  &.disabled {
    .template-content {
      cursor: default;
      border: none;
      background: transparent;
      border: 1px dashed #bec6cc;
      box-shadow: none;
      .centered-text {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        font-size: 0.75em;
        padding: 1em;
      }
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }
  &-image {
    position: relative;
    transition: 1s all cubic-bezier(0.25, 1, 0.25, 1);
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      transform: translate(-50%, -50%);
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  &-price {
    position: absolute;
    bottom: 1em;
    width: 100%;
    text-align: center;
    font-size: 0.6em;
    color: #2196f3;
    font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
    font-weight: 500;
    transition: 0.7s color cubic-bezier(0.25, 1, 0.25, 1);
    span {
      font-size: 1.25em;
    }
  }
  &-new {
    width: 3.5em;
    height: 3.5em;
    box-sizing: content-box;
    color: #2196f3;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 10px 0 0 0;
    &:after {
      content: "new";
      position: absolute;
      top: 35%;
      left: 35%;
      transform: translate(-50%, -50%) rotate(-45deg);
      font-size: 0.65em;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
    }
    &:before {
      width: 0;
      height: 0;
      border-top: 3.5em solid #e3f3ff;
      border-right: 3.5em solid transparent;
      transition: 0.7s border cubic-bezier(0.25, 1, 0.25, 1);
      position: absolute;
      top: 0;
      left: 0;
      content: "";
    }
  }
  &-name {
    font-size: 0.8rem;
    text-align: center;
    padding: 1rem 0;
    font-weight: 400;
  }

  &-color-layer {
    background: rgba(#2196f3, 1);
    transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
    opacity: 0;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    mix-blend-mode: multiply;
  }

  &-info {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);

    &-text {
      width: 90%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);
      color: #fff;
      text-align: center;
      font-size: 0.75em;
      transition: 0.9s all cubic-bezier(0.25, 1, 0.25, 1);
    }

    .streamedby {
      background: url(../assets/streamedby.svg);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      background-position: top right;
      top: 0.8em;
      right: 0.8em;
      width: 35%;
      opacity: 0.6;
      transition: 0.9s all 0.05s cubic-bezier(0.25, 1, 0.25, 1);
      transform: translate(0, 20%);
      &:after {
        content: "";
        display: block;
        padding-bottom: 20%;
      }
    }
  }

  &:not(.disabled):hover {
    .template-content {
      background-color: #f1f8ff;
      box-shadow: 0 -10px 0.6px rgba(255, 255, 255, 0.011),
        0 -8px 1.4px rgba(255, 255, 255, 0.012), 0 -6px 2.6px rgba(255, 255, 255, 0.013),
        0 -5px 4.7px rgba(255, 255, 255, 0.016), 0 -4px 8.8px rgba(255, 255, 255, 0.022),
        0 -3px 21px rgba(255, 255, 255, 0.15), 0 10px 0.6px rgba(0, 0, 0, 0.001),
        0 8px 1.4px rgba(0, 0, 0, 0.002), 0 6px 2.6px rgba(0, 0, 0, 0.003),
        0 5px 4.7px rgba(0, 0, 0, 0.006), 0 4px 8.8px rgba(0, 0, 0, 0.012),
        0 3px 21px rgba(0, 0, 0, 0.05);
      transform: translateY(-1%);
    }
    .template-image {
      color: #fff;
      opacity: 0.5;
    }
    .template-name {
      transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
    }
    .template-price {
      color: #fff;
    }
    .template-new {
      &:before {
        border-top-color: #fff;
      }
    }
    .template-color-layer {
      opacity: 1;
    }
    .template-info {
      opacity: 1;
      &-text {
        transform: translate(-50%, -50%);
      }
      .streamedby {
        transform: translate(0, 0);
      }
    }
  }
}
</style>
