<template lang="html">
  <div class="wrapper">
    <div class="open-discount-info" v-if="!show" @click="toggle()">We are moving</div>
    <div class="discount-info" :class="{ expanded: show }" v-if="show">
      <div class="background" @click="toggle()"></div>
      <div class="discount-info-wrapper">
        <div class="close-discount-info" @click="toggle()">
          <i class="fa fa-times"></i>
        </div>
        <div class="discount-info-wrapper-content">
          <div
            class="discount-info-title"
            v-html="
              `Time to jump off Studio
          <br> and head to Station`
            "
          ></div>
          <p>
            Please be aware that the Madington Studio application will be<br />discontinued
            at the end of September. We encourage all users to<br />transition to
            <a href="https://app.madington.com">Madington Station</a>, our new and
            improved platform.<br /><br />
            If you do not yet have access to
            <a href="https://app.madington.com">Madington Station</a>, please<br />
            request it by emailing us at
            <a href="mailto:info@madington.com">info@madington.com</a><br /><br />
            Thank you for your understanding!<br /><br /><br />
            <a href="https://app.madington.com"
              >Go to Madington Station
              <i
                class="arrow fal fa-chevron-right"
                style="position: absolute; margin-top: 1.5px; margin-left: 5px"
                aria-hidden="true"
              ></i
            ></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      data: false,
      // show: false,
    };
  },
  async mounted() {
    // const prismicAppData = await this.$prismic.api.getSingle("discount_button");
    // this.$set(this, "data", prismicAppData);
  },
  methods: {
    toggle() {
      this.$emit("toggle");
    },
  },
};
</script>

<style lang="scss">
.discount-info {
  p {
    a {
      color: #fff;
      text-decoration: none;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    backdrop-filter: blur(16px);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(16px);
  }
}
.discount-info {
  position: fixed;
  top: 0%;
  left: 0%;
  color: #fff;
  z-index: 5;
  &-wrapper {
    animation: fadeIn 0.8s cubic-bezier(0.25, 1, 0.25, 1) forwards;
    position: relative;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.75);
    width: fit-content;
    max-width: fit-content;
    backdrop-filter: blur(16px);
    border-radius: 10px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035), 0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
    &-content {
      padding: 119px 108px 68px 68px;
      position: relative;
    }
  }
  &-title {
    font-size: 1.7em;
    color: #fff;
    font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
    font-weight: 500;
    margin-bottom: 0.5em;
    z-index: 1;
  }
  * > span {
    color: #2fd07d;
  }
  p {
    font-size: 0.75em;
    font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
    color: #fff;
    line-height: 1.2;
    margin: 0;
    span {
      color: #2fd07d;
    }
    a:hover {
      color: #fff;
    }
    & + p {
      margin-top: 0.5em;
      a > * {
        color: #fff;
        text-decoration: none;
        font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
        font-weight: 500;
      }
    }
    a {
      color: #2fd07d;
      text-decoration: none;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;

      &:hover {
        color: #4e9c6a;
      }
    }
  }
}
*a {
  color: #fff;
  text-decoration: none;
  font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
  font-weight: 500;
}
.close-discount-info {
  color: #fff;
  opacity: 0.5;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 0.8em;
  z-index: 2;
  height: 1.5em;
  width: 1.5em;
  transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
  cursor: pointer;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    transform-origin: top left;
    vertical-align: middle;
    line-height: 1;
  }
  &:hover {
    // color: #fff;
    opacity: 1;
  }
}
.open-discount-info {
  position: fixed;
  animation: fadeIn 0.8s cubic-bezier(0.25, 1, 0.25, 1) forwards;
  width: fit-content;
  bottom: 24px;
  right: 170px;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 3em;
  padding: 0.55em 1em 0.55em;
  padding: 0.75em 2em 0.55em;
  font-size: 0.7em;
  cursor: pointer;
  transition: 0.7s color cubic-bezier(0.25, 1, 0.25, 1),
    0.7s background cubic-bezier(0.25, 1, 0.25, 1);
  line-height: 1;
  vertical-align: middle;
  span {
    font-size: 1.2em;
    color: #91cbf9;
    font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
    font-weight: 500;
    margin-right: 0.3em;
    line-height: 1;
    vertical-align: middle;
  }
  &:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.9);
  }
}
</style>
